
import "./App.css";
import HomeContainer from "./Components/HomeContainer";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Stack from "react-bootstrap/Stack";

function App() {
  return (
    <div className="App">
      <Row>
        <Col>
          <Stack
            gap={3}
            style={{
              height: 65,
              color: "#051650",
              fontSize: 36,
              textAlign: "center",
              fontFamily:'fantasy',
              letterSpacing: 5
              
            

            }}
          >
            <p style={{ marginTop: 10, marginLeft: 20, fontWeight:'bold', textShadow: '2px 2px 2px white' }}>
            APRCT SKILL DEVELOPMENT TRAINING
            </p>
            
          </Stack>
          <Stack
            gap={3}
            style={{
              height: 40,
              color: "#003566",
              fontSize: 14,
              textAlign: "center",
              fontFamily:'cursive',
              letterSpacing: 5
            }}
          >
            <p style={{ marginTop: 0, marginLeft: 20, fontWeight:'bold' }}>
            Get High End Training at Free of Cost
            </p>
            
          </Stack>
         
        </Col>
      </Row>
      <HomeContainer  />

      <Stack
        gap={3}
        style={{  height: 10, marginTop: 10 }}
      ></Stack>
      
    </div>
  );
}

export default App;
