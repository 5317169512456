import React, { Component } from "react";
import Container from "react-bootstrap/Container";
import QRModal from "./QRModal";
import Cards from "./Cards";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Stack from "react-bootstrap/Stack";
import Image from "react-bootstrap/Image";
import Founder1 from "../Assets/AllamsettiKesavaRao.jpg";
import Founder2 from "../Assets/AllamsettiSanthamani.jpg";

export default class Home extends Component {
  render() {
    return (
      <div>
        <Container>
          <Row style={{ marginTop: 20 }}></Row>
          <Row style={{ paddingTop: 20, paddingBottom: 20 }}>
            <Col>
              <Row>
                <Col>
                  <Image src={Founder1} thumbnail />
                </Col>
                <Col>
                  <p style={{ wordWrap: "break-word", marginTop: 50 }}>
                    <h5 style={{ color: "#003566", fontWeight: "bold" }}>
                      Allamsetti Kesava Rao
                    </h5>
                    <h6
                      style={{
                        color: "orange",
                        fontSize: 14,
                        fontWeight: "bold",
                      }}
                    >
                      Chartered Accountant{" "}
                    </h6>
                    <h6
                      style={{
                        color: "orange",
                        fontSize: 14,
                        fontWeight: "bold",
                      }}
                    >
                      Managing Trustee
                    </h6>
                  </p>
                </Col>
              </Row>
            </Col>
            <Col>
              <Row>
                <Col>
                  <Image src={Founder2} thumbnail />
                </Col>
                <Col>
                  <p style={{ wordWrap: "break-word", marginTop: 50 }}>
                    <h5 style={{ color: "#003566", fontWeight: "bold" }}>
                      Allamsetti Santhamani
                    </h5>{" "}
                    <h6
                      style={{
                        color: "orange",
                        fontSize: 14,
                        fontWeight: "bold",
                      }}
                    >
                      Trustee
                    </h6>
                  </p>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
        <Row
          style={{
            paddingRight: 200,
            paddingLeft: 200,
            paddingTop: 30,
            paddingBottom: 20,
            backgroundColor: "#00072D",
            color: "white",
          }}
        >
          <Col>
            <p style={{ wordWrap: "break-word" }}>
              APRCT is Registered Charitable Trust and to the charitable
              activities for poor and needy pepole of our Society. Main welfare
              activities of the Trust are payment of Scholar Ships to the poor,
              meritorious and handicapped students, rendering financial
              assistance to the windows by giving monthly pension. Also
              rendering financial assistance or provide all kinds of primary
              health care to needy pepole. We are seek your co-operation and
              esteemed pantronge by way participate in the noble cause.{" "}
            </p>

            <ul>
              <li>
                {" "}
                <p
                  style={{
                    color: "orange",
                    fontWeight: "bold",
                    fontStyle: "italic",
                    fontSize: 14,
                  }}
                >
                  &nbsp;&nbsp;&nbsp;PLEASE SEND YOUR DONATION by way of Cheque /
                  DD / Cash in favour of "M/S ALLAMSETTI PAPARAO CHARITABLE
                  TRUST".{" "}
                </p>
              </li>
              <li>
                <QRModal></QRModal>
              </li>
            </ul>

            <Row>
              <Col style={{ textAlign: "left" }}>
                <p>Thanking You</p>
              </Col>
              <Col style={{ textAlign: "right" }}>
                <p>
                  Yours Faithfully,
                  <p>
                    <lable style={{ color: "orange", fontWeight: "bold" }}>
                      C.A. ALLAMSETTI KESAVA RAO
                    </lable>
                    <p>Managing Trustee</p>
                  </p>
                  {/* <QRModal></QRModal> */}
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
        <Container>
          <Row style={{ padding: 20 }}>
            <Col>
              <h4 style={{ color: "orange", fontWeight: "bold" }}>About:</h4>
              <p style={{ wordWrap: "break-word", color: "#003566" }}>
                <lable style={{ color: "orange", fontWeight: "bold" }}>
                  Allamsetti Paparao Charitable Trust
                </lable>{" "}
                is a registered charitable trust driving charitable activities
                for poor and needy people of our Society. The Key welfare
                activities of the Trust are giving Healthcare Training to
                economically backward underprivileged women, Payment of Scholar
                Ships to the Poor and Meritorious and Handicapped Students,
                rendering financial assistance to the Widows by giving Monthly
                Pension, and also rendering financial assistance or provide{" "}
                <a
                  href="/about"
                  style={{ textDecoration: "none", fontSize: 14 }}
                >
                  Read More &#129174;&#129174;&#129174;{" "}
                </a>
              </p>
            </Col>
            <Col>
              <h4 style={{ color: "orange", fontWeight: "bold" }}>Career:</h4>
              <p style={{ wordWrap: "break-word", color: "#003566" }}>
                The above quote by Dr. A.P.J. Abdul Kalam advocates the
                significance of the women empowerment in the growth and
                development of a nation. The project is envisaged with an
                overarching goal of socio-economic empowerment of
                underprivileged women through promotion of sustainable
                livelihood opportunities. The proposed intervention will not
                only equip the selected underprivileged women with vocational
                skills but would also provide sustainable livelihood{" "}
                <a
                  href="/career"
                  style={{ textDecoration: "none", fontSize: 14 }}
                >
                  Read More &#129174;&#129174;&#129174;{" "}
                </a>
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <Stack gap={3}>
                <Cards />
              </Stack>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
