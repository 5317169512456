import React, { Component } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Stack from "react-bootstrap/Stack";
import Card from "react-bootstrap/Card";
import BedsideAssistant from "../Assets/Bedside-Assistant.jpg";
import { Download } from "react-bootstrap-icons";
import DownloadFile from "../Assets/Student-registaion-form.docx";
import Button from "react-bootstrap/Button";
import { Container } from "react-bootstrap";

export default class About extends Component {
  render() {
    return (
      <div>
        <Row>
          <Col>
            <Stack
              gap={3}
              style={{
                backgroundColor: "#003566",
                height: 50,
                width: "100%",
                color: "white",
                textAlign: "left",
                paddingTop: 5,
              }}
            >
              <h4 style={{ marginTop: 5, marginLeft: "10%",  fontWeight: "bold" }}>
                Bedside Assistant
              </h4>
            </Stack>
          </Col>
        </Row>
        <Container>
        <Row style={{ padding: 20 }}>
          <Col sm={9}>
            <Row>
              <Col>
                <Stack>
                  <p  style={{ color: "#003566" }}>
                    A bedside assistant, also known as a patient care assistant
                    or nursing assistant, is a healthcare professional who works
                    closely with patients in various healthcare settings such as
                    hospitals, nursing homes, and home care. Their role is to
                    provide direct care and support to patients, ensuring their
                    comfort, safety, and well-being.
                  </p>
                </Stack>
              </Col>
            </Row>
            <Row>
              <Col>
                <Stack>
                <h3 style={{ color: "orange", fontWeight: "bold" }}>
                      Selection Criteria
                    </h3>

                  <ul  style={{ color: "#003566" }}>
                    <li>
                      Age Group: The candidates should belong to the age group
                      of 18 to 45 years
                    </li>
                    <li>
                      Educational Qualification: Minimum 12th class
                      qualification
                    </li>
                    <li>
                      Socio-Economic background: Extremely poor families will be
                      given preference
                    </li>
                    <li>
                      Interest: Candidates who understand and show interest in
                      the value proposition of the training programme
                    </li>
                  </ul>
                </Stack>
              </Col>
            </Row>
          </Col>
          <Col sm={3}>
            <Card style={{ width: "14rem", textAlign: "center" }}>
              <Card.Img variant="top" src={BedsideAssistant} />
              <Card.Body>
                <Card.Text>Student registration form</Card.Text>
                <a href={DownloadFile} download>
                  {" "}
                  <Button variant="primary">
                    Download <Download />
                  </Button>
                </a>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        </Container>
      </div>
    );
  }
}
