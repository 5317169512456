import React, { Component } from "react";
import Row from "react-bootstrap/Row";
import { Container } from "react-bootstrap";

export default class About extends Component {
  render() {
    return (
      <div>
        <Container>
        
          <Row style={{ marginTop:20 }}>
            <h5 style={{ color: "orange", fontWeight: "bold" }}>
              {" "}
              Our Vision
            </h5>
            <p style={{ color: "#003566" }}>
              The Project is envisaged with an overarching goal of
              socio-economic empowerment of underprivileged women by creating a
              cadre of certified and trained healthcare workers and thereby
              enabling livelihood opportunities and improving their quality of
              life.
            </p>
          </Row>
          <Row>
            <h5 style={{ color: "orange", fontWeight: "bold" }}>
              Our Mission:
            </h5>
            <p style={{ color: "#003566" }}>
              The aim of the intervention is to address the shortage of health
              workers and also provide livelihood opportunities to
              underprivileged women from the intervention area through training
              with professional assistance with certification. A well-designed
              skill development programme will help them to break free from the
              shackles of deeply entrenched poverty and contributes towards the
              development. Our Trust also provide financial assistance to all
              kinds of primary health care, advanced and specialised diagnostic,
              medical, surgical and all other specialities and other treatments.
              We are giving support for feeding the poor and needy people and
              distributing medicines, clothing, clean drinking water, and
              self-help, self-support and self-employment for uplifting the
              people and in underprivileged sections.
              <ul style={{ marginTop: 10, color: "#003566" }}>
                <li>
                  To impart health-based skill development training to 100
                  underprivileged women hailing from economically and socially
                  marginalized families on General Duty Assistants, Operation
                  Theater technician, Bedside assistant and Lab Technician.
                </li>
                <li>
                  To promote confidence and presentation skills among
                  beneficiaries by incorporating aspects of English Speaking and
                  Personality Development in the course curriculum.
                </li>
                <li>
                  Providing the women an opportunity to work closely with
                  medical personnel and participate in various programmes for
                  prevention of diseases and promotion of healthcare.
                </li>
              </ul>
            </p>
            <p>
              <p
                style={{
                  color: "orange",
                  fontStyle: "italic",
                  fontWeight: "bold",
                }}
              >
                Why to get skilled from Allamsetti Paparao Charitable Trust
                Skill Development Training Programme?
              </p>
              <ul style={{ marginTop: 10, color: "#003566"  }}>
                <li>
                  we do provide high end training with efficient Training
                  Faculties and also provide the guidelines of experience
                  Doctors.
                </li>
                <li>
                  We do promote confidence and presentation skills among
                  beneficiaries by incorporating aspects of English Speaking and
                  Personality Development in the course curriculum.
                </li>
                <li>
                  We are also providing the women an opportunity to work closely
                  with medical personnel and participate in various programmes
                  for prevention of diseases and promotion of healthcare.
                </li>
              </ul>
            </p>

            <p
              style={{
                color: "orange",
                fontStyle: "italic",
                fontWeight: "bold",
              }}
            >
              Why HEALTH CARE PROFESSIONALS are need of after Covid Impact??
            </p>
            <p style={{ color: "#003566" }}>
              Covid-19 has affected the lives and livelihoods of people across
              the world. It has put a burden on the existing limited healthcare
              sector in India. One of the unintended consequences of Covid-19 is
              its impact on patients suffering from chronic / severe medical
              conditions that require prolonged hospitalization. These patients
              are more susceptible to contact communicable diseases like
              nosocomial infections at the hospital which can lead to further
              complications sometimes resulting in loss of life. There has been
              an increase in the number of elderly people who require constant
              care and support as the younger ones in their family are not
              living with them because of professional obligations. One of the
              possible ways of mitigating this issue is providing quality
              healthcare from professionally trained health workers. Given the
              proliferation of hospitals and health care start-ups in India,
              home health care services are becoming more and more important.
              However, the major impediment in ensuring continuity of such
              services is shortage of professionally trained health workers.
            </p>
            <p style={{ color: "#003566" }}>
              In Pandemic times Skill Development Training Program for
              Healthcare Professionals have become the need of the hour, not
              just to address the skill shortage that exist in the workforce but
              also to bring meaningful employment opportunities to the youth of
              this Country, which will help to build a strong pipeline of
              Paramedic Healthcare Professions who are work-ready to ensure the
              diagnostics and healthcare delivery system for years to come. This
              Skill Development training can give students to access to
              practical work experience, real-world technology and fast track
              their learning, thus securing their future and giving them a
              perfect exit to become entrepreneurs and workplace innovators of
              the future.
            </p>
          </Row>
        </Container>
      </div>
    );
  }
}
