import React from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import { Container, Nav, Navbar } from "react-bootstrap";
import NavDropdown from "react-bootstrap/NavDropdown";
import Image from "react-bootstrap/Image";
import Logo from "../Assets/logo.jpg";
import Home from "./Home";
import Contact from "./Contact";
import About from "./About";
import BedsideAssistant from "./BedsideAssistant";
import GeneralDutyAssistant from "./GeneralDutyAssistant";
import LabTechnician from "./LabTechnician";
import SoftSkills from "./SoftSkills";
import OperationTheatreTechnician from "./OperationTheatreTechnician";
import Career from "./Career";

function NavbarComp() {
  return (
    <div>
      <Router>
        <Navbar
        style={{backgroundColor:'#003566 !important', height:50}}
          expand="lg"
          className="bg-body-tertiary justify-content-between navbar"
          sticky="top"
          bg="dark"
          data-bs-theme="dark"
          
        >
          <Container>
            <Navbar.Brand href="/">
              {" "}
              <Image width="90" height="50" src={Logo} />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="mr-auto">
                <Nav.Link
                  as={Link}
                  to="/"
                  style={{ marginRight: "8%", marginLeft: "10%", width: 110, color: '#a8dae9' }}
                >
                  HOME
                </Nav.Link>
                <Nav.Link
                  as={Link}
                  to="/about"
                  style={{ marginRight: "8%", width: 110, color: '#a8dae9' }}
                >
                  {" "}
                  ABOUT
                </Nav.Link>
                <Nav.Link
                  as={Link}
                  to="/career"
                  style={{ marginRight: "8%", width: 110, color: '#a8dae9'}}
                >
                  {" "}
                  CAREER
                </Nav.Link>
                <NavDropdown
                  id="dropdown-basic-button"
                  variant="info"
                  title="TRAINING PROGRAM"
                  style={{ marginRight: "8%"}}
                >
                  <NavDropdown.Item
                    as={Link}
                    to="/generaldutyassistant"
                    style={{ marginRight: "8%", color: "#a8dae9" }}
                  >
                    General Duty Assistant
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    as={Link}
                    to="/labtechnician"
                    style={{ marginRight: "8%", color: "#a8dae9" }}
                  >
                    Lab Technician
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    as={Link}
                    to="/bedsideassistant"
                    style={{ marginRight: "8%", color: "#a8dae9" }}
                  >
                    Bedside Assistant
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    as={Link}
                    to="/operationtheatretechnician"
                    style={{ marginRight: "8%", color: "#a8dae9" }}
                  >
                    Operation Theatre technician
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    as={Link}
                    to="/softskills"
                    style={{ marginRight: "8%", color: "#a8dae9" }}
                  >
                    Soft Skills and Personality Development
                  </NavDropdown.Item>
                </NavDropdown>
                <Nav.Link
                  as={Link}
                  to="/contact"
                  style={{ marginRight: "8%", width: 350, color: '#a8dae9' }}
                >
                  CONTACT
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>

        <div>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/career" element={<Career />} />
            <Route
              path="/generaldutyassistant"
              element={<GeneralDutyAssistant />}
            />
            <Route path="/labtechnician" element={<LabTechnician />} />
            <Route path="/bedsideassistant" element={<BedsideAssistant />} />
            <Route
              path="/operationtheatretechnician"
              element={<OperationTheatreTechnician />}
            />
            <Route path="/softskills" element={<SoftSkills />} />
          </Routes>
        </div>
      </Router>
    </div>
  );
}

export default NavbarComp;
